<template>
  <div>
    <CRow class="form-group bg-light">
      <CCol cols="12" class="text-left">
        <CSelect
          label="Поверитель"
          :value.sync="worker"
          :options="users"></CSelect>
      </CCol>
      <CCol cols="1" v-if="rows>0" class="text-right">
        <CButton @click="collapse = !collapse" color="info" class="mb-2">
          Фильтры
        </CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCollapse
          :show="collapse" :duration="400"
          id="collapse-1" class="mt-2 form-group">
          <CCard>
            <CRow>
              <CCol sm="2">
                <div>
                  Период:
                </div>
                <date-range-picker
                  :locale-data="localeData"
                  v-model="dateRange"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :show-dropdowns="true"
                  :ranges="false"
                  @update="updateValues"
                >
                  <template #input="picker" style="min-width: 350px;">
                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                  </template>
                </date-range-picker>
              </CCol>
              <CCol sm="2">
                <div>
                  Номер акта:
                </div>
                    <CInput placeholder="ХХ-ХХ-ХХХХ" v-model="number_act"></CInput>
              </CCol>
              <CCol sm="2">
                <div>
                  Адрес:
                </div>
                    <CInput placeholder="" v-model="number_act"></CInput>
              </CCol>
              <CCol sm="3" class="form-inline">
                <input type="checkbox" v-model="act_good"> Пригодны
                <input type="checkbox" v-model="act_bad"> Не пригодны
                <input type="checkbox" v-model="act_brak"> Испорчен

<!--                <CInputCheckbox-->
<!--                  v-for="(option, optionIndex) in act_type_options"-->
<!--                  :key="key + option"-->
<!--                  :label="option"-->
<!--                  @input="currActTypes()"-->
<!--                  :custom="key > 1"-->
<!--                  :name="`Option 1${key}`"-->
<!--                  :checked="true"-->
<!--                  :inline="key % 2 === 1"-->
<!--                />-->
              </CCol>
              <CCol sm="1">
                <div>
                  &nbsp;
                </div>
                    <CButton @click="$emit('get-data', user, number_act)" color="info" class="mb-2">
                      искать
                    </CButton>
              </CCol>
              <CCol sm="1">
                <div>
                  &nbsp;
                </div>
                 <CButton @click="resetForm" color="secondary" class="mb-2">
                      сбросить
                 </CButton>
              </CCol>
            </CRow>
          </CCard>
        </CCollapse>
      </CCol>
    </CRow>
  </div>
</template>


<script>

import DateRangePicker from 'vue2-daterange-picker'
import { CFormCheck } from '@coreui/vue';

//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'ActMeterFilter',
  components: { DateRangePicker, CFormCheck },
  props: {
    users: {
      type: Array
    },
    rows: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      worker: null,
      number_act: null,
      minDate: null,
      maxDate: null,
      dateRange: {
        startDate: '2021-09-01',
        endDate: '2021-09-30',
      },
      localeData: {
          direction: 'ltr',
          format: 'dd/mm/yyyy',
          separator: ' - ',
          applyLabel: 'Применить',
          cancelLabel: 'Отмена',
          weekLabel: 'Нед',
          customRangeLabel: 'Выбранный период',
          daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          monthNames: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
          firstDay: 0,
      },
      collapse: false,
      act_type_options: ['Пригодны','Не пригодны','Испорчен'],
      act_good: true,
      act_bad: true,
      act_brak: true,
    }
  },
  created() {
    this.defaultDateRange(false);
  },
  mounted() {
    this.worker = this.$store.getters.worker.value;
  },
  computed: {
    user() {
      return this.$root.user;
    },
  },
  filters: {
    dateCell (value) {
      let dt = new Date(value)

      return dt.getDate()
    },
    date (val) {
      return val ? new Intl.DateTimeFormat('en-GB').format(val) : ''
    }
  },
  watch: {
    worker(val) {
      let data = this.users.filter(item => item.value === val);
      let worker = data.length === 1 ? data[0] : {value: null, label: null};
      this.$store.dispatch('SET_WORKER', worker);
      if (val)
        this.$emit('get-data');
    },
  },
  methods: {
    dateFormat (classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < new Date()
      }
      return classes
    },
    updateValues() {
      console.log('set-period', this.dateRange.startDate, this.dateRange.endDate);
      this.$emit('set-period', this.dateRange.startDate, this.dateRange.endDate);
    },
    resetForm()
    {
      this.number_act = null;
      this.defaultDateRange(true);
      this.$emit('reset-data', this.user);
      this.$emit('set-period', this.dateRange.startDate, this.dateRange.endDate);
    },
    defaultDateRange(all) {
      let now = new Date();
      let y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(now);
      let m = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(now);

      this.dateRange.startDate = all ? `2018-01-01` :`${y}-${m}-01`;
      this.dateRange.endDate = `${y}-${m}-${this.getLastDayOfMonth(y,m)}`;
    },
    getLastDayOfMonth(year, month) {
      let date = new Date(year, month, 0);
      return new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    },
    currActTypes(event) {
      console.log('curr', event)
    }
  }
}

</script>
